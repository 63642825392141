import React, { useState, forwardRef, useImperativeHandle } from "react";
import { useNavigate } from "react-router-dom";

import services from "../../../services";
import ZenviIcon from "../../../assets/zenvi-lg.svg";
import ButtonComponent from "../../Button/Button";
import { useForm } from '../../../FormContext';
import { JIRO_FORM_INPUTS_STEP_TWO } from "../../../constants";
import InputComponent from "../../Input/Input";
import "./StepTwo.css";

const {
  saveUserInfo,
} = services;

const StepTwo = forwardRef((props, ref) => {
  const [isPrivacyPolicyChecked, setIsPrivacyPolicyChecked] = useState(false);
  const { formData, updateFormData } = useForm(); // useForm from context
  const navigate = useNavigate();

  useImperativeHandle(ref, () => ({
    getData: () => formData,
  }));

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "userType") {
      updateFormData({
        is_company: value === "sociedad",
      });
    } else {
      updateFormData({
        [name]: value,
      });
    }
  };


  const handleNextStep = async (e) => {
    e.preventDefault();
    console.log(formData);
    try {
      await saveUserInfo(formData);
      navigate('/onboard-new-addresses');
    } catch (err) {
    }
    
  };

  return (
    <>
    <div className="form-container">
      <img className="zenvi-icon" src={ZenviIcon} alt="Zenvi Logo" />
      <form className="jiro-form" onSubmit={handleNextStep}>
      <div className="headerContainer">
        <h1>Crea tu usuario en Zenvi para empezar a ahorrar</h1>
      </div>
        <div className="formGrid">
          <div className="first-row">
            {JIRO_FORM_INPUTS_STEP_TWO.slice(0, 2).map((input) => (
              <InputComponent
                key={input.name}
                {...input}
                value={formData[input.name]}
                onChange={handleChange}
                disabled={input.name === "email"}
              />
            ))}
          </div>
          <div className="second-row">
            {JIRO_FORM_INPUTS_STEP_TWO.slice(2).map((input) => (
              <InputComponent
                key={input.name}
                {...input}
                value={formData[input.name]}
                onChange={handleChange}
                disabled={input.name === "email"}
              />
            ))}
          </div>
          <div className="radio-buttons">
            <label>
              <input
                type="radio"
                name="userType"
                value="particular"
                checked={!formData.is_company}
                onChange={handleChange}
              />
              Particular
            </label>
            <label>
              <input
                type="radio"
                name="userType"
                value="sociedad"
                checked={formData.is_company}
                onChange={handleChange}
              />
              Sociedad
            </label>
          </div>
        </div>
        <div className="checkboxContainer">
          <input
            type="checkbox"
            id="politicaPrivacidad"
            name="politicaPrivacidad"
            onChange={(e) => setIsPrivacyPolicyChecked(e.target.checked)}
          />
          <label htmlFor="politicaPrivacidad">
          Acepto la <a href="https://storage.googleapis.com/shareable-content/web_legal_docs.pdf" target="_blank" rel="noopener noreferrer">política de privacidad de datos</a>
          </label>
        </div>
          <div className="button-container">
            <ButtonComponent type="submit" text="Enviar" disabled={!isPrivacyPolicyChecked}/>
          </div>
        </form>
      </div>
    </>
  );
});

export default StepTwo;
