import React, { createContext, useContext, useState, useEffect } from 'react';

// Create a FormContext
const FormContext = createContext();

// Keys for localStorage
const FORM_DATA_KEY = 'formData';
const CONTRACT_RESULT_KEY = 'contractResult';
const SIGN_URL_KEY = 'signUrl';

// Create a provider component
export const FormProvider = ({ children }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    name: '',
    surname: '',
    id_number: '',
    phone_number: '',
    is_company: false,
    iban: '',
  });

  const [contractResult, setContractResult] = useState(null);
  const [signUrl, setSignUrl] = useState(null);

  // Load formData, contractResult, and signUrl from localStorage on mount
  useEffect(() => {
    const storedFormData = localStorage.getItem(FORM_DATA_KEY);
    const storedContractResult = localStorage.getItem(CONTRACT_RESULT_KEY);
    const storedSignUrl = localStorage.getItem(SIGN_URL_KEY);

    if (storedFormData) {
      setFormData(JSON.parse(storedFormData));
    }
    if (storedContractResult) {
      setContractResult(JSON.parse(storedContractResult));
    }
    if (storedSignUrl) {
      setSignUrl(JSON.parse(storedSignUrl));
    }
  }, []);

  // Save formData to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem(FORM_DATA_KEY, JSON.stringify(formData));
  }, [formData]);

  // Save contractResult to localStorage whenever it changes
  useEffect(() => {
    if (contractResult) {
      localStorage.setItem(CONTRACT_RESULT_KEY, JSON.stringify(contractResult));
    }
  }, [contractResult]);

  // Save signUrl to localStorage whenever it changes
  useEffect(() => {
    if (signUrl) {
      localStorage.setItem(SIGN_URL_KEY, JSON.stringify(signUrl));
    }
  }, [signUrl]);

  const updateFormData = (newData) => {
    setFormData((prevData) => ({ ...prevData, ...newData }));
  };

  return (
    <FormContext.Provider value={{ formData, updateFormData, contractResult, setContractResult, signUrl, setSignUrl }}>
      {children}
    </FormContext.Provider>
  );
};

// Custom hook to use the FormContext
export const useForm = () => useContext(FormContext);
