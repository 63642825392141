import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./index.css";
import "./App.css";
import { FormProvider } from "./FormContext";
import JiroForm from "./components/JiroForm";
import JiroResult from "./components/JiroResult";
import { ROUTES } from "./constants";
import StepOne from "./components/JiroForm/StepOne/StepOne";
import StepTwo from "./components/JiroForm/StepTwo/StepTwo";
import StepThree from "./components/JiroForm/StepThree/StepThree";
import StepFour from "./components/JiroForm/StepFour/StepFour";
import StepFive from "./components/JiroForm/StepFive/StepFive";
import StepSix from "./components/JiroForm/StepSix/StepSix";
import StepDropboxSign from "./components/JiroForm/StepDropboxSign/StepDropboxSign";

function App() {
  return (
    <FormProvider>
      <Router>
        <Routes>
          {/* <Route path="/login" element={<StepOne />} /> */}
          <Route path="/" element={<StepOne />} />
          <Route path="/new-user" element={<StepTwo />} />
          <Route path="/onboard-new-addresses" element={<StepThree />} />
          <Route path="/gas-n-light-estimated-results" element={<StepFour />} />
          <Route path="/end-onboarding" element={<StepFive />} />
          <Route path="/successful-onboarding" element={<StepSix />} />
          <Route path="/auth-sign" element={<StepDropboxSign />} />
        </Routes>
      </Router>
    </FormProvider>
  );
}

// function App() {
//   return (
//     <Router>
//       <Routes>
//         <Route path={ROUTES.JiroForm} element={<JiroForm />} />
//         <Route path={ROUTES.JiroResult} element={<JiroResult />} />
//       </Routes>
//     </Router>
//   );
// }

export default App;
