import React, { useState, forwardRef, useImperativeHandle } from "react";
import { useNavigate } from "react-router-dom";

import services from "../../../services";
import ZenviIcon from "../../../assets/zenvi-lg.svg";
import { useForm } from '../../../FormContext';
import ButtonComponent from "../../Button/Button";
import FacturaCard from "../../FacturaCard/FacturaCard";
import JiroSpinner from "../../JiroSpinner/JiroSpinner";
import "./StepThree.css";

const {
  saveUserContracts,
  isIBANRegistered,
} = services;

const StepThree = forwardRef(({ userInfo }, ref) => {
  const defaultFactura = {
    file: null,
    address: "",
    zip_code: "",
    previous_account_holder_name: "",
    previous_account_holder_is_company: false,
  };
  const [facturas, setFacturas] = useState([defaultFactura]);
  const [loading, setLoading] = useState(false);
  const { formData, updateFormData, setContractResult } = useForm(); // Added setContractResult
  const [countdownTime, setCountdownTime] = useState(null);
  const navigate = useNavigate();

  useImperativeHandle(ref, () => ({
    getData: () => ({
      ...userInfo,
      facturas,
    }),
  }));

  const handleFileUpload = (index, e) => {
    const files = e.target.files || []
    if (files.length > 0) {
      updateFactura(index, { files });
    }
  };

  const updateFactura = (index, updatedData) => {
    setFacturas((prevFacturas) =>
      prevFacturas.map((factura, i) =>
        i === index ? { ...factura, ...updatedData } : factura
      )
    );
  };

  const addFactura = () => {
    setFacturas((prevFacturas) => [...prevFacturas, { ...defaultFactura }]);
  };

  const numberOfFilesInRequest = (data) => {
    if (!data || !Array.isArray(data)) {
      return 0;
    }
  
    return data.reduce((sum, factura) => {
      if (factura.files && Array.isArray(factura.files)) {
        return sum + factura.files.length;
      }
      return sum;
    }, 0);
  };

  const handleNextStep = async (e) => {
    e.preventDefault();
    const numberOfFiles = numberOfFilesInRequest(facturas);
    const secondsToWait = numberOfFiles * 15;
    setCountdownTime(secondsToWait);
    setLoading(true);
    const billsNForm = { ...formData, facturas: facturas };
    try {
      const res = await saveUserContracts(billsNForm);  
      setLoading(false);
      setCountdownTime(null);
      setContractResult(res);
      navigate("/gas-n-light-estimated-results")
    }
    catch (err) {
      setLoading(false);
      setCountdownTime(null);
      const resIban = await isIBANRegistered(formData);
      if (resIban.success) {
        navigate("/gas-n-light-estimated-results")
        // setCurrentStep(6);
        // return 6;
      } else {
        navigate("/gas-n-light-estimated-results")
        // setCurrentStep(5);
        // return 5;
      }
    }
    navigate('/gas-n-light-estimated-results');    
  };

  

  return (
    <>
    <div className="form-container">
    {loading && <JiroSpinner countdownTime={countdownTime} />}
      <img className="zenvi-icon" src={ZenviIcon} alt="Zenvi Logo" />
      <form className="jiro-form" onSubmit={handleNextStep}>
        <div className="headerContainer">
          <h1>Introduce los datos de tus hogares</h1>
          <p>
            Con una factura de luz por hogar es suficiente, pero cuantas mas facturas adjuntes mejor comprenderemos tus necesidades
          </p>
        </div>
          {facturas.length > 0 &&
            facturas.map((factura, index) => (
              <FacturaCard
                key={index}
                factura={factura}
                index={index}
                updateFactura={updateFactura}
                handleFileUpload={handleFileUpload}
              />
            ))}
          <div className="custom-button-wrapper" onClick={addFactura}>
            <button className="custom-button">+</button>
            <span className="custom-button-label">Añade una nueva vivienda</span>
          </div>
          <div className="button-container">
            <ButtonComponent type="submit" text="Enviar"/>
          </div>
        </form>
      </div>
    </>
  );
});

export default StepThree;