import React, { useState, forwardRef, useImperativeHandle } from "react";
import { useNavigate } from "react-router-dom";

import ButtonComponent from "../../Button/Button";
import services from "../../../services";
import ZenviIcon from "../../../assets/zenvi-lg.svg";
import { useForm } from "../../../FormContext"; // Import the context hook
import ZenviArrowIcon from "../../../assets/zenvi-arrow.svg";
import "./StepFour.css";

const {
  isIBANRegistered,
} = services;

const StepFour = React.forwardRef((props, ref) => {
  const { formData, updateFormData } = useForm();
  const navigate = useNavigate();
  const { contractResult } = useForm();

  useImperativeHandle(ref, () => ({
    getData: () => formData,
  }));


  const handleNextStep = async (e) => {
    e.preventDefault();
    try {
      const res = await isIBANRegistered(formData);
      if (res.success) {
        navigate('/successful-onboarding');
      } else {
        navigate('/end-onboarding');
      }
    } catch (err) {
    }
  };

  return (
    <>
    <div className="form-container">
      <img className="zenvi-icon" src={ZenviIcon} alt="Zenvi Logo" />
      <form className="jiro-form" onSubmit={handleNextStep}>
        <div>
          <div className="headerContainer">
            <h2>
              En tus siguientes facturas podrás disfrutar del siguiente ahorro
            </h2>
          </div>
          <div className="resultCard-container">
            {/* Check if contractResult exists and render it */}
            {contractResult && contractResult.map((contract, index) => (
              <div className="resultCard" key={index}>
                <div className="resultCard-content">
                  <div className="resultCard-section">
                    <span>Dirección</span>
                    <h2>{contract.address}</h2>
                  </div>
                  <div className="zenvi-arrow-icon-container">
                    <img
                      className="zenvi-arrow-icon"
                      src={ZenviArrowIcon}
                      alt="Zenvi Arrow Logo"
                    />
                    {contract.supplier_name && (
                      <div className="provider-text">{`Con el proveedor ${contract.supplier_name}`}</div>
                    )}
                  </div>
                  <div className="resultCard-section">
                    <span>Ahorro estimado con Zenvi</span>
                    <h2>{contract.ahorro_estimado_anual}€/año</h2>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
          <div className="button-container">
            <ButtonComponent type="submit" text="Enviar"/>
          </div>
      </form>
    </div>
    </>
  );
});

export default StepFour;