import React, { useEffect } from "react";
import HelloSign from 'hellosign-embedded';
import { useNavigate } from "react-router-dom";

import services from "../../../services";
import { useForm } from "../../../FormContext";
import ZenviIcon from "../../../assets/zenvi-lg.svg";

const {
  onboardUser,
} = services;

const StepDropboxSign = (props) => {
  const { signUrl } = useForm();
  const { formData } = useForm();
  const navigate = useNavigate();

  const handleSignedAuth = async () => {
    // There might be an exploit here where a user can make a request to the onboardUser endpoint without signing the document
    try {
      onboardUser(formData)
      navigate('/successful-onboarding');
    } catch (error) {
      console.error("Error during step test:", error);
    }
  };
  useEffect(() => {

    const embeddedSigningUrl = signUrl;

    if (embeddedSigningUrl) {
      const url = new URL(embeddedSigningUrl);
      const clientId = url.searchParams.get('client_id');

      if (clientId) {
        HelloSign.init(clientId);

        HelloSign.open({
          url: embeddedSigningUrl,
          container: document.getElementById('embedded-signing'),
          height: 500,
          width: 700,
          messageListener: function(eventData) {
            console.log(eventData);
            if (eventData.event === 'signature_request_signed') {
              handleSignedAuth();
            }
          }
        });
      } else {
        console.error("Client ID not found in the URL");
      }
    }
  }, [props.embeddedSigningUrl, props]);

  return (
    <div className="form-container">
      <img className="zenvi-icon" src={ZenviIcon} alt="Zenvi Logo" />
      <h1>Firma la autorizacion para que podamos negociar en tu nombre</h1>
    <div style={StepDropboxSignContainer}>
      <div id="embedded-signing" style={embeddedSigningContainer}></div>
    </div>
    </div>
  );
};

const StepDropboxSignContainer = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "1.5rem",
};

const embeddedSigningContainer = {
  width: '700px',
  height: '500px',
};

export default StepDropboxSign;