import React, { useState, forwardRef, useImperativeHandle } from "react";
import { useNavigate } from "react-router-dom";

import services from "../../../services";
import ButtonComponent from "../../Button/Button";
import { useForm } from "../../../FormContext";
import ZenviIcon from "../../../assets/zenvi-lg.svg";
import { JIRO_FORM_INPUTS_STEP_FIVE } from "../../../constants";
import InputComponent from "../../Input/Input";
import "./StepFive.css";

const {
  registerUserWithIBAN,
  generateDropboxSignUrl,
  isUserOnboarded,
} = services;

const pickFields = (obj, fields) => {
  return fields.reduce((acc, field) => {
    if (obj.hasOwnProperty(field)) {
      acc[field] = obj[field];
    }
    return acc;
  }, {});
};

const StepFive = React.forwardRef((props, ref) => {
  const { formData, updateFormData, setSignUrl } = useForm();
  const navigate = useNavigate();

  useImperativeHandle(ref, () => ({
    getData: () => formData,
  }));

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateFormData({ [name]: value });
  };

  const handleNextStep = async (e) => {
    e.preventDefault()
    try {
      await registerUserWithIBAN(formData);
      const resUserOnboarded = await isUserOnboarded(formData);
      if (!resUserOnboarded.success){
        const dropboxSignUrl = await generateDropboxSignUrl(formData);
        setSignUrl(dropboxSignUrl.sign_url);
        // setCurrentStep(8);
        navigate('/auth-sign');
      }
      else {
        // setCurrentStep(6);
        // return 6;
      }
    } catch (err) {
    }
  };

  return (
    <>
    <div className="form-container">
      <img className="zenvi-icon" src={ZenviIcon} alt="Zenvi Logo" />
      <form className="jiro-form" onSubmit={handleNextStep}>
    <div className="headerContainer">
      <h2>
      Necesitamos tu IBAN para que la nueva comercializadora pueda realizar el proceso
      </h2>
      <p>
      Te pediremos confirmación a través de un correo electrónico antes de hacer ningún cambio
      </p>
    </div>
      <InputComponent
        key={JIRO_FORM_INPUTS_STEP_FIVE.name}
        {...JIRO_FORM_INPUTS_STEP_FIVE}
        value={formData[JIRO_FORM_INPUTS_STEP_FIVE.name]}
        onChange={handleChange}
      />
      <div className="button-container">
        <ButtonComponent type="submit" text="Enviar"/>
      </div>
      </form>
    </div>
    </>
  );
});

export default StepFive;
