import React, { useState, forwardRef, useImperativeHandle } from "react";
import { useNavigate } from "react-router-dom";

import services from "../../../services";
import { useForm } from '../../../FormContext';
import ButtonComponent from "../../Button/Button";
import { JIRO_FORM_INPUTS_STEP_ONE } from "../../../constants";
import InputComponent from "../../Input/Input";
import ZenviIcon from "../../../assets/zenvi-lg.svg";

const {
  registerLogin,
  isIBANRegistered,
  generateDropboxSignUrl,
  isUserOnboarded,
} = services;

const StepOne = forwardRef((props, ref) => {
  const { formData, updateFormData, setSignUrl } = useForm();
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useImperativeHandle(ref, () => ({
    getData: () => formData,
  }));

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateFormData({ [name]: value });
  };

  const handleNextStep = async (e) => {
    e.preventDefault();
    try {
      const res = await registerLogin(formData);
      localStorage.setItem('token', res.access_token);
      const resIbanRegistered = await isIBANRegistered(formData);
      const resUserOnboarded = await isUserOnboarded(formData);
      setError(null);

      if (resIbanRegistered.success && !resUserOnboarded.success){
        const dropboxSignUrl = await generateDropboxSignUrl(formData)
        setSignUrl(dropboxSignUrl.sign_url)
        navigate('/auth-sign');
      } else {
        if (res.success) {
          navigate('/onboard-new-addresses');
        } else {
          navigate('/new-user');
        }
      }
    } catch (err){
      setError("Email o Código de invitación introducido no válido");
    }
  };

  return (
    <div className="form-container">
      <img className="zenvi-icon" src={ZenviIcon} alt="Zenvi Logo" />
      <form className="jiro-form" onSubmit={handleNextStep}>
        <div style={stepOneContainer}>
          <h1>Introduce tus credenciales</h1>
          {JIRO_FORM_INPUTS_STEP_ONE.map((input, idx) => (
            <InputComponent
              style={{ width: "80%" }}
              key={idx}
              {...input}
              value={formData[input.name]}
              onChange={handleChange}
            />
          ))}
          {error && <p className="error-message">{error}</p>}
          <div className="button-container">
            <ButtonComponent type="submit" text="Siguiente" />
          </div>
          <div style={emailContainer}>
            <a href="mailto:facturas@zenvi.es?subject=Quiero%20un%20c%C3%B3digo%20de%20invitaci%C3%B3n">
              ¿No tienes código?
            </a>
            <p>Déjanos tu email para obtener un acceso anticipado</p>
          </div>
        </div>
      </form>
    </div>
  );
});

const stepOneContainer = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "1.5rem",
};

const emailContainer = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "6px",
};

export default StepOne;